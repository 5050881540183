import React, {Component} from 'react';
import Header from '../components/Header/Header';
import { Layout } from '../layout/Layout';

class errorpage extends Component {
    render() {
        return (
            <>
                <Layout>
                    <Header />
                    <div style={{backgroundColor: '#f4f8fd'}} >
                        <div className="container d-flex align-content-center flex-wrap" width="100%" style={{height: `calc(100vh - 64px)`}}>
                            <div className="row d-flex justify-content-center">
                                <div className="col align-self-center text-center">
                                    <h2 className="mt-0">You seem to be lost.</h2>
                                    <a className="nav-link btn-outline-primary" type="button" href="/">Go Back Home</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Layout>
            </>
        )
    }
}

export default errorpage